import { $, $$, $id, delegate, offset } from './helper/utils';
import outline from './helper/outline';
// import inView from './helper/inView';
import navigation from './components/navigation';
import customVideo from './components/custom-video';

const sliderMap = new Map();
const sliderDragging = new Map();

outline();
navigation();
customVideo();

export default function () {

  if ($('.siema') !== null) {

    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.wrapper-compare') !== null) {
    import('./components/compareslide')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }
  if ($('.newsletterform') !== null) {
    import('./components/newsletter')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }
  if ($('a[href^="#"]') !== null) {
    import('./components/smoothhref')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }
  if ($('#header') !== null) {
    import('./components/mobilenav')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  // if ($('.lightbox') !== null) {
  //   import('./components/lightbox.js')
  //     .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // };

  if ($$('.nav-tabs, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, {
        duration: 0.3,
        opacity: 0
      }, {
        opacity: 1,
        ease: 'linear'
      });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        for (const el of $$('.nav-tabs .tab-link')) {
          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
  if ($('.layout-3.cards') !== null) {
    $('.layout-3.cards').closest('.container-sector').classList.add('cards-container');
  }
}
