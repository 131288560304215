import { $, $$, addEventListenerAll, offset } from '../helper/utils';
import { PHONEMEDIA } from '../helper/constants';
// import focusLoop from '../helper/focusLoop';

const isMobile = document.documentElement.classList.contains('is-mobile');
const showMenuBtn = $$('.show-menu');
const nav = $('.mobile-nav');
const mainNav = $('.main-nav');
const navBar1 = $$('.show-menu .bar1');
const navBar2 = $$('.show-menu .bar2');
const navBar3 = $$('.show-menu .bar3');
const state = {
  navOpen: false,
  activeLink: null,
};
let scrollTop;

export default function navigation() {
  if (showMenuBtn === null) {
    return;
  }

  // main timeline showing navigation
  const timeline = gsap.timeline({
    paused: true,
    onComplete() {
      // update local state
      state.navOpen = true;

      // make navigation visible to voice assistants
      nav.setAttribute('aria-hidden', false);

      // focus active nav item, or just mainnav if not a mobile device
      if (isMobile === false) {
        if (state.activeLink !== null) {
          state.activeLink.focus();
        } else {
          mainNav.focus();
        }
      }
    },
    onReverseComplete() {
      state.navOpen = false;
      nav.setAttribute('aria-hidden', true);
      // document.body.classList.remove('nav-open');
    },
  });

  // add tweens
  timeline
    .to(navBar2, {
      duration: 0.1,
      scaleX: 0,
      ease: 'sine.in',
    })
    .to(navBar1, {
      duration: 0.1,
      y: 9,
      ease: 'sine.out',
    }, '<')
    .to(navBar3, {
      duration: 0.1,
      y: -9,
      ease: 'sine.out',
    }, '<')
    .to(navBar1, {
      duration: 0.125,
      scaleX: 1,
      y: 5,
      ease: 'sine.out',
    })
    .to(navBar3, {
      duration: 0.125,
      scaleX: 1,
      y: -5,
      ease: 'sine.out',
    }, '<');

  // open nav on menu button click
  addEventListenerAll(showMenuBtn, 'click', () => {
    // ignore click while tweening
    if (timeline.totalProgress() > 0 && timeline.totalProgress() < 1) {
      return;
    }

    // close nav if already open
    if (state.navOpen === true) {
      // reenable body scroll and set scroll position to last previous
      document.documentElement.classList.remove('noscroll');
      window.scrollTo(0, scrollTop);

      requestAnimationFrame(() => {
        gsap.fromTo(nav, {
          xPercent: 0,
        }, {
          duration: 0.275,
          xPercent: 100,
          ease: 'sine.inOut',
          onComplete() {
            gsap.set(nav, { visibility: 'hidden' });
          },
        });

        // make reverse animations faster
        timeline.timeScale(1.25);
        timeline.reverse();
      });

      return;
    }

    // get current vertical scroll position to revert to after closing nav (mostly for mobile)
    scrollTop = window.scrollY;

    // prevent body scroll
    document.documentElement.classList.add('noscroll');

    // document.body.classList.add('nav-open');

    requestAnimationFrame(() => {
      // reset timeline speed to default and start
      timeline.timeScale(1);
      timeline.play();

      gsap.fromTo(nav, {
        xPercent: 100,
      }, {
        duration: 0.375,
        visibility: 'inherit',
        xPercent: 0,
        ease: 'sine.out',
      });
    });
  }, { passive: true });
}
