export default function customVideo() {
  let videoBox = document.getElementsByClassName('videobox')
  if (videoBox === null) {
    return;
  }
  for (let i = 0; i < videoBox.length; i++) {
    let videoBTN = videoBox[i].getElementsByClassName('video-play-button')[0];
    let video = videoBox[i].getElementsByClassName('videobox-content')[0];
    videoBTN.addEventListener('click', function (e) {
      video.setAttribute("controls","controls");
      video.play()
      this.classList.add('hidden')
    })
  }
}
